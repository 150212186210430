import React from "react";
import { useStaticQuery, graphql } from "gatsby";


import Hero from "@shared/hero";
import { Query } from "@graphql-types";

const CaseStudyHero = () => {
  const { sanityCaseStudiesPage }: Query = useStaticQuery(graphql`
    {
      sanityCaseStudiesPage {
          hero {
            ...sanityHero
          }
        }
    }
  `);

  return <Hero data={sanityCaseStudiesPage?.hero} height="67vh" isCaseStudy />;
};

export default CaseStudyHero;
