import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";

import CaseStudyHero from "@components/case-study/caseStudyHero";
import { Query } from "@graphql-types";
import Anchored from "@shared/anchored";
import SEO from "@shared/seo";

const CaseStudyPage = () => {

  const { sanityCaseStudiesPage }: Query = useStaticQuery(graphql`
    {
      sanityCaseStudiesPage {
        caseStudiesContent {
          _key
          anchoredId
          anchoredTitle
          elements {
            ... on SanityColumn {
              ...sanityColumn
            }
            ... on SanityFullWidthContent {
              ...sanityFullWidthContent
            }
            ... on SanityLeftRightBlock {
              ...sanityLeftRightBlock
            }
          }
        }
          seo {
            ...sanitySeo
        }
      }
    }
  `);

  if (sanityCaseStudiesPage == null) {
    return null;
  }

  return (
    <>
      <SEO seoData={sanityCaseStudiesPage?.seo} />
      <CaseStudyHero></CaseStudyHero>
      <Anchored baseSlug="case-studies" data={sanityCaseStudiesPage?.caseStudiesContent} isStudyCase />
    </>
  );
};

export default CaseStudyPage;
